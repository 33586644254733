// Generated by Framer (1d71865)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {Bo09WXr4W: {hover: true}, Golk97Xvb: {hover: true}, ox9sKl2Jy: {hover: true}};

const cycleOrder = ["Bo09WXr4W", "Golk97Xvb", "ox9sKl2Jy"];

const serializationHash = "framer-QMVVm"

const variantClassNames = {Bo09WXr4W: "framer-v-d8epa3", Golk97Xvb: "framer-v-xs70mj", ox9sKl2Jy: "framer-v-1l0w0og"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Large: "Bo09WXr4W", Medium: "Golk97Xvb", Small: "ox9sKl2Jy"}

const getProps = ({height, id, image, link, logo, width, ...props}) => { return {...props, JGCSp4qJA: logo ?? props.JGCSp4qJA ?? {src: "https://framerusercontent.com/images/XkQWYsoFPBrFXAb9TjaU4UVbIfU.svg"}, LkFvXfSja: image ?? props.LkFvXfSja ?? {src: "https://framerusercontent.com/images/TcA0Iptw0kyfFXnLtZ2Q4wDl55w.jpg?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/TcA0Iptw0kyfFXnLtZ2Q4wDl55w.jpg?scale-down-to=512 512w,https://framerusercontent.com/images/TcA0Iptw0kyfFXnLtZ2Q4wDl55w.jpg?scale-down-to=1024 1024w,https://framerusercontent.com/images/TcA0Iptw0kyfFXnLtZ2Q4wDl55w.jpg 1920w"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Bo09WXr4W", veC1LniDQ: link ?? props.veC1LniDQ} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};logo?: {src: string; srcSet?: string};link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, LkFvXfSja, JGCSp4qJA, veC1LniDQ, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Bo09WXr4W", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={veC1LniDQ}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-d8epa3", className, classNames)} framer-d7g97r`} data-framer-name={"Large"} layoutDependency={layoutDependency} layoutId={"Bo09WXr4W"} ref={ref ?? ref1} style={{backgroundColor: "rgb(0, 0, 0)", ...style}} {...addPropertyOverrides({"Bo09WXr4W-hover": {"data-framer-name": undefined}, "Golk97Xvb-hover": {"data-framer-name": undefined}, "ox9sKl2Jy-hover": {"data-framer-name": undefined}, Golk97Xvb: {"data-framer-name": "Medium"}, ox9sKl2Jy: {"data-framer-name": "Small"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", sizes: `calc(${componentViewport?.width || "100vw"} - 20px)`, ...toResponsiveImage(LkFvXfSja), ...{ positionX: "center", positionY: "center" }}} className={"framer-32uy06"} data-framer-name={"Image"} layoutDependency={layoutDependency} layoutId={"xhGfspRni"} style={{scale: 1}} variants={{"Bo09WXr4W-hover": {scale: 1.1}, "Golk97Xvb-hover": {scale: 1.1}, "ox9sKl2Jy-hover": {scale: 1.1}}}/><Image background={{alt: "", fit: "fit", pixelHeight: 58, pixelWidth: 328, sizes: "304px", ...toResponsiveImage(JGCSp4qJA), ...{ positionX: "center", positionY: "center" }}} className={"framer-d4a9p2"} data-framer-name={"Logo"} layoutDependency={layoutDependency} layoutId={"LueZGEIMf"} transformTemplate={transformTemplate1}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-QMVVm.framer-d7g97r, .framer-QMVVm .framer-d7g97r { display: block; }", ".framer-QMVVm.framer-d8epa3 { cursor: pointer; height: 343px; overflow: hidden; position: relative; text-decoration: none; width: 567px; }", ".framer-QMVVm .framer-32uy06 { flex: none; height: 100%; left: 0px; position: absolute; top: 0px; width: 100%; }", ".framer-QMVVm .framer-d4a9p2 { aspect-ratio: 4.164383561643835 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 72px); left: 50%; max-height: 72px; overflow: hidden; position: absolute; top: 50%; width: 304px; }", ".framer-QMVVm.framer-v-xs70mj.framer-d8epa3, .framer-QMVVm.framer-v-1l0w0og.framer-d8epa3, .framer-QMVVm.framer-v-d8epa3.hover.framer-d8epa3 { aspect-ratio: 1.653061224489796 / 1; height: var(--framer-aspect-ratio-supported, 343px); }", ".framer-QMVVm.framer-v-xs70mj .framer-d4a9p2 { height: var(--framer-aspect-ratio-supported, 36px); max-height: 36px; }", ".framer-QMVVm.framer-v-1l0w0og .framer-d4a9p2 { height: var(--framer-aspect-ratio-supported, 28px); max-height: 28px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 343
 * @framerIntrinsicWidth 567
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Golk97Xvb":{"layout":["fixed","fixed"]},"ox9sKl2Jy":{"layout":["fixed","fixed"]},"M4M4sMnay":{"layout":["fixed","fixed"]},"OSFeuexP3":{"layout":["fixed","fixed"]},"gtV3Xtuq_":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"LkFvXfSja":"image","JGCSp4qJA":"logo","veC1LniDQ":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerr98j04xXF: React.ComponentType<Props> = withCSS(Component, css, "framer-QMVVm") as typeof Component;
export default Framerr98j04xXF;

Framerr98j04xXF.displayName = "Study Image";

Framerr98j04xXF.defaultProps = {height: 343, width: 567};

addPropertyControls(Framerr98j04xXF, {variant: {options: ["Bo09WXr4W", "Golk97Xvb", "ox9sKl2Jy"], optionTitles: ["Large", "Medium", "Small"], title: "Variant", type: ControlType.Enum}, LkFvXfSja: {__defaultAssetReference: "data:framer/asset-reference,TcA0Iptw0kyfFXnLtZ2Q4wDl55w.jpg?originalFilename=pickawood-6tAIO3pxde4-unsplash.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}, JGCSp4qJA: {__defaultAssetReference: "data:framer/asset-reference,XkQWYsoFPBrFXAb9TjaU4UVbIfU.svg?originalFilename=logo.svg&preferredSize=auto", title: "Logo", type: ControlType.ResponsiveImage}, veC1LniDQ: {title: "Link", type: ControlType.Link}} as any)

addFonts(Framerr98j04xXF, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})